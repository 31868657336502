export enum AlignAlias {
  rowReverse = 'row-reverse',
  columnReverse = 'column-reverse',
  wrapReverse = 'wrap-reverse',
  start = 'flex-start',
  end = 'flex-end',
  between = 'space-between',
  around = 'space-around',
  evenly = 'space-evenly'
}
